<template>
  <v-btn
    v-if="active"
    color="primary"
    fab
    dark
    fixed
    bottom
    right
    @click="moveTop()"
  >
    <v-icon class="pt-1">mdi-apple-keyboard-control</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "ScrollTop",
  data() {
    return {
      scrollY: window.scrollY,
      active: false,
    };
  },
  watch: {
    scrollY(v) {
      if (v > 20) {
        this.active = true;
      } else {
        this.active = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.$nextTick(() => {
        this.scrollY = window.scrollY;
      });
    });
  },
  methods: {
    moveTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style></style>
