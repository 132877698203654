<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-card-title>언론사 라이브</v-card-title>
      <v-row no-gutters class="px-5">
        <v-col cols="12" lg="3" sm="12">
          <v-autocomplete
            v-model="media_id"
            :items="media_items"
            label="언론사"
            item-text="name"
            item-value="id"
            no-data-text="존재하지 않는 언론사입니다."
            hide-details
            @change="getData()"
          >
          </v-autocomplete>
          <!-- <v-autocomplete
              v-model="media_ids"
              :items="media_items"
              label="언론사"
              item-text="name"
              item-value="id"
              no-data-text="존재하지 않는 언론사입니다."
              multiple
              @change="getData()"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="toggle()">
                  <v-list-item-action>
                    <v-icon
                      :color="media_ids.length > 0 ? 'blue darken-1' : ''"
                    >
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>전체</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="data">
                <v-chip v-if="data.index === 0 && selectedAll"
                  ><span class="mr-1">전체</span>
                  <v-icon @click="toggle()" small>$delete</v-icon></v-chip
                >
                <v-chip v-if="data.index < 7 && selectedEach">
                  <span class="mr-1">{{ data.item.name }}</span>
                  <v-icon @click="data.parent.selectItem(data.item)" small>
                    $delete
                  </v-icon>
                </v-chip>
                <span
                  v-if="data.index === 7 && selectedEach"
                  class="grey--text text-caption"
                >
                  (외 {{ media_ids.length - data.index }}개)
                </span>
              </template>
            </v-autocomplete> -->
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" lg="1" sm="12">
          <v-menu
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="startDate"
                readonly
                v-bind="attrs"
                v-on="on"
                label="시작 날짜"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              ref="startDate"
              @input="startMenu = false"
              :max="startMaxDate"
              locale="ko-KR"
              no-title
              reactive
            ></v-date-picker>
          </v-menu>
        </v-col>
        <p class="text-center mx-auto px-5 mt-5">~</p>
        <v-col cols="12" lg="1" sm="12" class="mr-5">
          <v-menu
            v-model="endMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="endDate"
                readonly
                v-bind="attrs"
                v-on="on"
                label="종료 날짜"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              ref="endDate"
              @input="endMenu = false"
              :min="startDate"
              :max="endMaxDate"
              locale="ko-KR"
              no-title
              reactive
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-btn color="primary" @click="getData()" class="mx-auto mt-3"
          >검색</v-btn
        >
      </v-row>
      <v-row>
        <v-col class="px-7">
          <v-progress-circular
            v-if="loading"
            :indeterminate="loading"
            class="progress_circular"
          ></v-progress-circular>
          <div
            v-if="chart"
            :style="`height:${chartHeight}px;`"
            class="chart_wrap"
          >
            <div ref="chart" style="height: 100%"></div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-row class="mt-5">
      <v-col>
        <v-simple-table class="table">
          <template>
            <thead>
              <tr>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  통합
                </th>
                <th
                  v-for="(date, i) in dates"
                  :key="i"
                  class="text-right"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                  :style="{ color: getWeekend(date) }"
                >
                  {{ date }}
                  {{ "(" + getDay(date) + ")" }}
                </th>
              </tr>
            </thead>
            <tbody style="white-space: nowrap">
              <tr v-for="(item, i) in totalItems" :key="i">
                <td
                  style="
                    border-right: 1px solid rgba(0, 0, 0, 0.12);
                    width: 320px;
                  "
                >
                  {{ item.name }}
                </td>
                <td
                  v-for="(value, i) in item.values"
                  :key="i"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                  class="text-right"
                >
                  <p v-if="i === 0" class="cnt ma-0">
                    {{ value.cnt.toLocaleString() }}
                  </p>
                  <p
                    v-if="i > 0"
                    class="cnt"
                    :style="{
                      color: calcIncrease(value.increase),
                    }"
                  >
                    {{ value.cnt.toLocaleString() }}
                  </p>
                  <p class="increase" v-if="i > 0">
                    <span
                      v-if="value.increase > 0"
                      :style="{
                        color: calcIncrease(value.increase),
                      }"
                    >
                      <v-icon
                        :style="{
                          color: calcIncrease(value.increase),
                        }"
                        >mdi-menu-up</v-icon
                      >
                      {{ value.increase }} %
                    </span>
                    <span
                      v-if="value.increase < 0"
                      :style="{
                        color: calcIncrease(value.increase),
                      }"
                    >
                      <v-icon
                        :style="{
                          color: calcIncrease(value.increase, i),
                        }"
                        >mdi-menu-down</v-icon
                      >
                      {{ value.increase }} %
                    </span>
                    <v-icon v-if="value.increase === 0">mdi-equal</v-icon>
                    <v-icon v-if="value.increase === '-'" style="color: red"
                      >mdi-menu-up</v-icon
                    >
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row class="mb-16">
      <v-col>
        <v-simple-table class="table">
          <template>
            <thead>
              <tr>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  언론사
                </th>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  구분
                </th>
                <th
                  v-for="(date, i) in dates"
                  :key="i"
                  class="text-right"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                  :style="{ color: getWeekend(date) }"
                >
                  {{ date }}
                  ({{ getDay(date) }})
                </th>
              </tr>
            </thead>
            <tbody class="media_table" style="white-space: nowrap">
              <tr v-for="(items, i) in tableItems" :key="i">
                <td style="width: 200px">
                  {{ items.media }}
                </td>
                <td style="width: 120px">
                  <p class="td">기사 뷰</p>
                  <p
                    class="td"
                    style="
                      border-top: 1px solid rgba(0, 0, 0, 0.12);
                      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    "
                  >
                    설문 노출
                  </p>
                  <p
                    class="td"
                    style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
                  >
                    설문 응답
                  </p>
                  <p class="td">CVR</p>
                </td>
                <td
                  v-for="(value, i) in items.values"
                  :key="i"
                  class="text-right"
                >
                  <div>
                    <p
                      v-if="i === 0"
                      class="cnt ma-0"
                      style="line-height: 77px"
                    >
                      {{ value.types.view.cnt.toLocaleString() }}
                    </p>
                    <p
                      v-if="i > 0"
                      class="cnt"
                      :style="{
                        color: calcIncrease(value.types.view.increase),
                      }"
                    >
                      {{ value.types.view.cnt.toLocaleString() }}
                    </p>
                    <p
                      v-if="i > 0"
                      class="increase"
                      :style="{
                        color: calcIncrease(value.types.view.increase),
                      }"
                    >
                      <span v-if="value.types.view.increase > 0">
                        <v-icon
                          :style="{
                            color: calcIncrease(value.types.view.increase),
                          }"
                          >mdi-menu-up</v-icon
                        >
                        {{ value.types.view.increase }} %
                      </span>
                      <span v-if="value.types.view.increase < 0">
                        <v-icon
                          :style="{
                            color: calcIncrease(value.types.view.increase),
                          }"
                          >mdi-menu-down</v-icon
                        >
                        {{ value.types.view.increase }} %
                      </span>
                      <v-icon v-if="value.types.view.increase === 0"
                        >mdi-equal</v-icon
                      >
                      <v-icon
                        v-if="value.types.view.increase === '-'"
                        style="color: red"
                        >mdi-menu-up</v-icon
                      >
                    </p>
                  </div>
                  <div
                    style="
                      border-top: 1px solid rgba(0, 0, 0, 0.12);
                      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    "
                  >
                    <p
                      v-if="i === 0"
                      class="cnt ma-0"
                      style="line-height: 77px"
                    >
                      {{ value.types.impression.cnt.toLocaleString() }}
                    </p>
                    <p
                      v-if="i > 0"
                      class="cnt"
                      :style="{
                        color: calcIncrease(value.types.impression.increase),
                      }"
                    >
                      {{ value.types.impression.cnt.toLocaleString() }}
                    </p>
                    <p
                      v-if="i > 0"
                      class="increase"
                      :style="{
                        color: calcIncrease(value.types.impression.increase),
                      }"
                    >
                      <span v-if="value.types.impression.increase > 0">
                        <v-icon
                          :style="{
                            color: calcIncrease(
                              value.types.impression.increase
                            ),
                          }"
                          >mdi-menu-up</v-icon
                        >
                        {{ value.types.impression.increase }} %
                      </span>
                      <span v-if="value.types.impression.increase < 0">
                        <v-icon
                          :style="{
                            color: calcIncrease(
                              value.types.impression.increase
                            ),
                          }"
                          >mdi-menu-down</v-icon
                        >
                        {{ value.types.impression.increase }} %
                      </span>
                      <v-icon v-if="value.types.impression.increase === 0"
                        >mdi-equal</v-icon
                      >
                      <v-icon
                        v-if="value.types.impression.increase === '-'"
                        style="color: red"
                        >mdi-menu-up</v-icon
                      >
                    </p>
                  </div>
                  <div style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)">
                    <p
                      v-if="i === 0"
                      class="cnt ma-0"
                      style="line-height: 77px"
                    >
                      {{ value.types.survey.cnt.toLocaleString() }}
                    </p>
                    <p
                      v-if="i > 0"
                      class="cnt"
                      :style="{
                        color: calcIncrease(value.types.survey.increase),
                      }"
                    >
                      {{ value.types.survey.cnt.toLocaleString() }}
                    </p>
                    <p
                      v-if="i > 0"
                      class="increase"
                      :style="{
                        color: calcIncrease(value.types.survey.increase),
                      }"
                    >
                      <span v-if="value.types.survey.increase > 0">
                        <v-icon
                          :style="{
                            color: calcIncrease(value.types.survey.increase),
                          }"
                          >mdi-menu-up</v-icon
                        >
                        {{ value.types.survey.increase }} %
                      </span>
                      <span v-if="value.types.survey.increase < 0">
                        <v-icon
                          :style="{
                            color: calcIncrease(value.types.survey.increase),
                          }"
                          >mdi-menu-down</v-icon
                        >
                        {{ value.types.survey.increase }} %
                      </span>
                      <v-icon v-if="value.types.survey.increase === 0"
                        >mdi-equal</v-icon
                      >
                      <v-icon
                        v-if="value.types.survey.increase === '-'"
                        style="color: red"
                        >mdi-menu-up</v-icon
                      >
                    </p>
                  </div>
                  <div>
                    <p
                      v-if="i === 0"
                      class="cnt ma-0"
                      style="line-height: 77px"
                    >
                      {{ value.types.cvr.cnt.toLocaleString() }}
                    </p>
                    <p
                      v-if="i > 0"
                      class="cnt"
                      :style="{
                        color: calcIncrease(value.types.cvr.increase),
                      }"
                    >
                      {{ value.types.cvr.cnt.toLocaleString() }}
                    </p>
                    <p
                      v-if="i > 0"
                      class="increase"
                      :style="{
                        color: calcIncrease(value.types.cvr.increase),
                      }"
                    >
                      <span v-if="value.types.cvr.increase > 0">
                        <v-icon
                          :style="{
                            color: calcIncrease(value.types.cvr.increase),
                          }"
                          >mdi-menu-up</v-icon
                        >
                        {{ value.types.cvr.increase }} %
                      </span>
                      <span v-if="value.types.cvr.increase < 0">
                        <v-icon
                          :style="{
                            color: calcIncrease(value.types.cvr.increase),
                          }"
                          >mdi-menu-down</v-icon
                        >
                        {{ value.types.cvr.increase }} %
                      </span>
                      <v-icon v-if="value.types.cvr.increase === 0"
                        >mdi-equal</v-icon
                      >
                      <v-icon
                        v-if="value.types.cvr.increase === '-'"
                        style="color: red"
                        >mdi-menu-up</v-icon
                      >
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <ScrollTop />
  </v-container>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import ScrollTop from "@/components/common/ScrollTop.vue";

export default {
  name: "MediaLive",
  components: {
    ScrollTop,
  },
  data() {
    return {
      // media_ids: [],
      media_id: "",
      media_items: [
        {
          id: "",
          name: "전체",
        },
      ],

      today: moment().format("YYYY-MM-DD"),
      startDate: null,
      startMenu: false,
      endDate: null,
      endMenu: false,
      startMaxDate: moment(this.endDate).format("YYYY-MM-DD"),
      endMaxDate: moment(this.today).format("YYYY-MM-DD"),
      formatStartDate: null,
      formatEndDate: null,

      // 차트옵션 공통 값
      colorPalette: ["#599BFF", "#FE83B7", "#FFBC57", "#26C7AA"],
      chart: false,
      chartHeight: 0,

      loading: false,
      dates: [],
      totalItems: [],
      tableItems: [],
      views: [],
      impressions: [],
      surveys: [],
      cvrs: [],
    };
  },
  computed: {
    selectedAll() {
      return this.media_ids.length === this.media_items.length;
    },
    selectedEach() {
      return this.media_ids.length > 0 && !this.selectedAll;
    },
    icon() {
      if (this.selectedAll) return "mdi-close-box";
      if (this.selectedEach) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  mounted() {
    this.initDate();
    this.getMedia();
    this.getData();
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAll) {
          this.media_ids = [];
        } else {
          this.media_ids = this.media_items.slice();
        }
        this.getData();
      });
    },

    initDate() {
      this.startDate = moment(this.today).subtract(7, "d").format("YYYY-MM-DD");
      this.endDate = this.today;
      this.formatStartDate = moment(this.startDate).format("YYYY-MM-DD");
      this.formatEndDate = moment(this.endDate).format("YYYY-MM-DD");
    },

    calcIncrease(value) {
      if (typeof value === "number") {
        if (value > 0) {
          return "red";
        } else if (value < 0) {
          return "blue";
        } else if (value === 0) {
          return "rgba(0, 0, 0, 0.87)";
        }
      } else if (typeof value === "string") {
        return "red";
      }
    },

    getWeekend(date) {
      if (moment(date).day() === 6) {
        return "blue";
      } else if (moment(date).day() === 0) {
        return "red";
      } else {
        return "rgba(0,0,0,.87)";
      }
    },

    getDay(date) {
      if (moment(date).day() === 0) {
        return "일";
      } else if (moment(date).day() === 1) {
        return "월";
      } else if (moment(date).day() === 2) {
        return "화";
      } else if (moment(date).day() === 3) {
        return "수";
      } else if (moment(date).day() === 4) {
        return "목";
      } else if (moment(date).day() === 5) {
        return "금";
      } else if (moment(date).day() === 6) {
        return "토";
      }
    },

    getWeek(date) {
      const weekOfMonth = (m) =>
        m.week() - moment(m).startOf("month").week() + 1;
      const nowDate = moment(date).utc(true);
      const final = nowDate.format("MM월 ") + weekOfMonth(nowDate) + "주";
      return final;
    },

    getMonth(date) {
      const nowDate = moment(date).utc(true);
      const final = nowDate.format("YYYY년 MM") + "월";
      return final;
    },

    getMedia() {
      this.axios.get("api/v1/manage/list/medias").then((res) => {
        if (res.data.list.data.length > 0) {
          res.data.list.data.map((item) => {
            this.media_items.push({
              id: item.id,
              name: item.name,
            });
          });
          // this.media_items.map((el) => {
          //   this.media_ids.push(el.id);
          // });
        }
      });
    },

    resetChartField() {
      this.dates = [];
      this.totalItems = [];
      this.tableItems = [];
      this.views = [];
      this.impressions = [];
      this.surveys = [];
      this.cvrs = [];
    },

    async getData() {
      this.loading = true;
      this.resetChartField();

      let date = [];
      let totalItems = [];
      let tableItems = [];
      let views = [];
      let impressions = [];
      let surveys = [];
      let cvrs = [];
      let chartArr = [];

      let params = {
        start_at: this.startDate,
        end_at: this.endDate,
      };
      // if (this.selectedEach) {
      //   params["media_ids"] = this.media_ids;
      // }
      if (this.media_id !== "") {
        params["media_ids[]"] = this.media_id;
      }
      await this.axios
        .get(`api/v1/stats/live/media`, {
          params,
        })
        .then((res) => {
          if (res.data.stats.length > 0) {
            this.chart = true;
            this.chartHeight = 350;

            let arr = res.data.stats[0].dates;
            let dates = Object.values(arr);
            dates.map((el) => {
              date.push(el.date);
            });
            arr.map((el) => {
              chartArr.push({
                values: el.types,
              });
            });

            let viewArr = [];
            let impressionArr = [];
            let surveyArr = [];
            let cvrArr = [];
            chartArr.map((el) => {
              viewArr.push(el.values.view);
              impressionArr.push(el.values.impression);
              surveyArr.push(el.values.survey);
              cvrArr.push(el.values.cvr);

              views.push(el.values.view.cnt);
              impressions.push(el.values.impression.cnt);
              surveys.push(el.values.survey.cnt);
              cvrs.push(el.values.cvr.cnt);
            });

            totalItems.push({
              name: "기사 뷰",
              values: viewArr,
            });
            totalItems.push({
              name: "설문 노출",
              values: impressionArr,
            });
            totalItems.push({
              name: "설문 응답",
              values: surveyArr,
            });
            totalItems.push({
              name: "CVR",
              values: cvrArr,
            });

            res.data.stats.map((el) => {
              let values = Object.values(el.dates);
              tableItems.push({
                media: el.media_name,
                values: values,
              });
            });
            tableItems = tableItems.splice(1);

            this.dates = date;
            this.totalItems = totalItems;
            this.tableItems = tableItems;
            this.views = views;
            this.impressions = impressions;
            this.surveys = surveys;
            this.cvrs = cvrs;
          }
        })
        .catch(() => {
          this.chart = false;
          this.loading = false;
        });

      if (this.chart) {
        this.getChart();
      }
      this.loading = false;
    },

    async getChart() {
      let max = Math.max.apply(null, this.cvrs);
      this.number = max.toString().length;

      this.$nextTick(() => {
        const chartDom = this.$refs.chart;
        let myChart = echarts.init(chartDom);

        myChart.setOption({
          color: this.colorPalette,
          tooltip: {
            trigger: "axis",
            formatter: (params) => {
              let item = "";
              params.forEach((param) => {
                item += `<div style="display:flex;justify-content:space-between;text-align:left"><span style="margin-right:10px">${
                  param.marker
                }${
                  param.seriesName
                }</span><span style="font-weight:600">${param.value.toLocaleString()}</span></div>`;
              });

              let intervalValue = params[0].name;
              if (this.interval === "weekly") {
                return `<p style="margin-bottom: 10px;text-align:left">${this.getWeek(
                  intervalValue
                )}</p> ${item}`;
              } else if (this.interval === "monthly") {
                return `<p style="margin-bottom: 10px;text-align:left">${this.getMonth(
                  intervalValue
                )}</p> ${item}`;
              } else {
                return `<p style="margin-bottom: 10px;text-align:left">${intervalValue}</p> ${item}`;
              }
            },
          },
          legend: {
            data: ["기사 뷰", "설문 노출", "설문 응답", "CVR"],
            orient: "horizontal",
            top: "bottom",
          },
          grid: {
            left: "0%",
            top: "5%",
            right: "20px",
            bottom: "15%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: this.dates,
            axisTick: { show: false },
            axisLabel: {
              textStyle: {
                color: (value) => {
                  if (moment(value).day() === 6) {
                    return "blue";
                  } else if (moment(value).day() === 0) {
                    return "red";
                  } else {
                    return "#000";
                  }
                },
              },
            },
          },
          yAxis: [
            {
              type: "value",
              alignTicks: true,
              axisLine: {
                show: true,
              },
            },
            {
              type: "value",
              alignTicks: true,
              position: "right",
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#FFBC57",
                },
              },
              axisLabel: {
                textStyle: {
                  color: "#FFBC57",
                },
              },
            },
            {
              type: "value",
              alignTicks: true,
              offset: this.number < 4 ? 40 : this.number * 11,
              position: "right",
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#26C7AA",
                },
              },
              axisLabel: {
                textStyle: {
                  color: "#26C7AA",
                },
              },
            },
          ],
          series: [
            {
              name: "기사 뷰",
              type: "line",
              data: this.views,
              emphasis: {
                focus: "series",
              },
              symbol: "circle",
            },
            {
              name: "설문 노출",
              type: "line",
              data: this.impressions,
              emphasis: {
                focus: "series",
              },
              symbol: "circle",
            },
            {
              name: "설문 응답",
              type: "line",
              data: this.surveys,
              yAxisIndex: 1,
              emphasis: {
                focus: "series",
              },
              symbol: "circle",
            },
            {
              name: "CVR",
              type: "line",
              data: this.cvrs,
              yAxisIndex: 2,
              emphasis: {
                focus: "series",
              },
              symbol: "circle",
            },
          ],
        });
        window.addEventListener("resize", myChart.resize);
      });
    },
  },
};
</script>
<style scoped>
.media_table tr td {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
}
.media_table tr:last-child td {
  border-bottom: unset !important;
}
.td {
  margin-bottom: 0;
  line-height: 77px;
}
.cnt {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
}
.increase {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: bold;
}
.table {
  overflow-x: auto;
}
.progress_circular {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
